import { Flex, Input, Text } from "@chakra-ui/react"


type AddressInputsProp = {
    city: string;
    setCity: (e: string) => void;
    country: string;
    setCountry: (e: string) => void;
    addressLine1: string;
    setAddressLine1: (e: string) => void;
    addressLine2: string;
    setAddressLine2: (e: string) => void;
    postalCode: string;
    setPostalCode: (e: string) => void;
}


const AddressInputs = ({
    city,
    setCity,
    country,
    setCountry,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    postalCode,
    setPostalCode
}: AddressInputsProp) => {
    return (
        <>
            <Flex direction={"row"} h="60px">
                <Text w={"20%"}>City: </Text>
                <Input
                    type="text"
                    name="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    w={"70%"}
                />
            </Flex>
            <Flex direction={"row"} h="60px">
                <Text w={"20%"}>City: </Text>
                <Input
                    type="text"
                    name="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    w={"70%"}
                />
            </Flex>
            <Flex direction={"row"} h="60px">
                <Text w={"20%"}>Address Line 1: </Text>
                <Input
                    type="text"
                    name="Address Line 1"
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                    w={"70%"}
                />
            </Flex>
            <Flex direction={"row"} h="60px">
                <Text w={"20%"}>Address Line 2: </Text>
                <Input
                    type="text"
                    name="Address Line 2"
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                    w={"70%"}
                />
            </Flex>
            <Flex direction={"row"} h="60px">
                <Text w={"20%"}>ZIP Code: </Text>
                <Input
                    type="text"
                    name="ZIP Code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    w={"70%"}
                />
            </Flex>
        </>
    )
}

export default AddressInputs;
import { ChakraProvider, Box, Grid, theme, Text } from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const PaymentSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/")
    }, 3000)
    return () => clearTimeout(timeout)
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <Text mt={4} fontSize="2xl" fontWeight="bold">
            Congratulations
          </Text>
          <Text mt={2}>
            Payment method setup successfully
          </Text>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};

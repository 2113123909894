import React from 'react';

interface ErrorMessageProps {
  children: React.ReactNode;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    {children}
  </div>
);

export default ErrorMessage;

import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

interface CardFieldProps {
  onChange: (event: { error: any; complete: boolean }) => void;
}

const CARD_OPTIONS = {
    iconStyle: 'solid' as "solid" | "default" | undefined,
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#fff',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#87BBFD',
        },
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
      },
    },
  };
  

const CardField: React.FC<CardFieldProps> = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

export default CardField;

import { ChakraProvider, Box, Grid, theme, Text } from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const SuccessPage = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const accountId = location.search.split('=')[1];



  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(`/create-client?accountId=${accountId}`)
    }, 3000)
    return () => clearTimeout(timeout)
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <Text mt={4} fontSize="2xl" fontWeight="bold">
            Congratulations
          </Text>
          <Text mt={2}>
            Your Account has been registered!
          </Text>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};

import React, { useState, useEffect } from 'react';
import { apiClient as axios } from "../axiosclient";
import {
    ChakraProvider,
    Box,
    Grid,
    Button,
    Stack,
    Text,
    Flex,
    Spinner,
    Input,
    Textarea,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    useToast
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { ResponseData, Product } from '../types/tierTypes';
import TopBar from '../components/TopBar';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const GoodBetterBest: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<any>([]);
    const [newProductName, setNewProductName] = useState('');
    const [newProductDescription, setNewProductDescription] = useState('');
    const [tiers, setTiers] = useState<
        {
            tierName: string,
            monthlyPrice: number,
            quarterlyPrice: number,
            yearlyPrice: number,
        }[]
    >([{
        tierName: "",
        monthlyPrice: 0,
        quarterlyPrice: 0,
        yearlyPrice: 0,
    }]);
    const [error, setError] = useState<string>('');
    const toast = useToast();

    const navigate = useNavigate();

    const connectedAccountId = localStorage.getItem('accountId');
    const customerId = localStorage.getItem('customerId');
    const defaultPaymentMethod = localStorage.getItem('defaultPaymentMethod');

    useEffect(() => {
        getAllProducts();
    }, []);

    const getAllProducts = async () => {
        try {
            setIsLoading(true);
            const productsResponse = await axios.get(`${BASE_URL}/good-better-best-products/get-products/${connectedAccountId}/${customerId}`);
            let products = productsResponse.data.products.map((p: any) => {
                const prices = Object.keys(p.prices).map((key) => {
                    const sortedPrices = p.prices[key].sort((a: any, b: any) => a.unit_amount - b.unit_amount)
                    return sortedPrices;
                }).sort((a: any, b: any) => {
                    return a[0].unit_amount - b[0].unit_amount
                });
                return {
                    ...p,
                    prices
                }
            })
            setProducts(products);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBuyCancelProduct = async (priceId: string, productId: string, subscriptionId: string) => {
        try {
            if (subscriptionId) {
                const res = await axios.post(`${BASE_URL}/subscription/cancel`, {
                    subscriptionId,
                    connectedAccountId: connectedAccountId,
                });
                toast({
                    title: 'Subscription cancelled',
                    description: "Your subscription is cancelled.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                if (!defaultPaymentMethod) {
                    // handle error for no default payment method
                    toast({
                        title: 'No default payment method.',
                        description: "Please select default payment method.",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                } else if (connectedAccountId && customerId && !!priceId && !!productId) {
                    const res = await axios.post(`${BASE_URL}/subscription/create`, {
                        priceId: priceId,
                        customerId: customerId,
                        connectedAccountId: connectedAccountId,
                        paymentMethodId: defaultPaymentMethod,
                        productId: productId
                    });
                    toast({
                        title: 'Payment successfull',
                        description: "Your payment for the product is successfull.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
            getAllProducts();
        } catch (error) {
            toast({
                title: 'Something went wrong',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }

    }


    const handleCreateProduct = async () => {
        try {
            if (!newProductDescription || !newProductName) {
                setError("Please fill in all fields")
                return;
            }
            const errors = tiers.map((tier) => {
                if (!tier.tierName || tier.monthlyPrice < 1 || tier.quarterlyPrice < 1 || tier.yearlyPrice < 1) {
                    setError("Please fill in all fields")
                    return "error";
                }
            }).filter((e: any) => !!e);
            if (errors.length >= 1) return
            console.log("HERE", { name: newProductName, description: newProductDescription, tiers });
            await axios.post(`${BASE_URL}/good-better-best-products/create-product`, {
                name: newProductName,
                description: newProductDescription,
                tiers: tiers.map(tier => {
                    return {
                        tierName: tier.tierName,
                        monthlyPriceInCents: tier.monthlyPrice * 100,
                        quarterlyPriceInCents: tier.quarterlyPrice * 100,
                        yearlyPriceInCents: tier.yearlyPrice * 100,
                    }
                }),
                connectedAccountId,
            }).then(() => {
                setNewProductName("");
                setNewProductDescription("");
                setTiers([{
                    tierName: "",
                    monthlyPrice: 0,
                    quarterlyPrice: 0,
                    yearlyPrice: 0,
                }])
            });
            await getAllProducts()
        } catch (error) {
            console.log({ error })
        }
    }

    const addNewFields = () => {
        setTiers([...tiers, {
            tierName: "",
            monthlyPrice: 0,
            quarterlyPrice: 0,
            yearlyPrice: 0,
        }])
    }

    const setTierValues = (index: number, key: "tierName" | "monthlyPrice" | "quarterlyPrice" | "yearlyPrice", value: string | number) => {
        const tempTiers = tiers;
        //@ts-ignore
        tempTiers[index][key] = value;
        setTiers([...tempTiers]);
    }


    return (
        <ChakraProvider>
            <TopBar />
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3} templateColumns="3fr 7fr" gap={4}>
                    <Box>
                        <Stack spacing={3} mb={4}>
                            <Input placeholder="Product Name" value={newProductName} onChange={(e) => setNewProductName(e.target.value)} />
                            <Textarea placeholder="Product Description" value={newProductDescription} onChange={(e) => setNewProductDescription(e.target.value)} />
                            {tiers.map((tier, index) => (
                                <div key={index}>
                                    <Text fontSize="sm" textAlign="start">Tier details: </Text>
                                    <Flex flexDirection="row" gap={4} justifyContent="flex-start" alignItems="center">
                                        <div style={{ width: "90%" }}>
                                            <Input mb={2} placeholder="Tier Name" value={tier.tierName} onChange={(e) => setTierValues(index, "tierName", e.target.value)} />
                                            <div>
                                                <Text fontSize="sm" textAlign="start">Monthly Price: </Text>
                                                <InputGroup mb={2}>
                                                    <InputLeftAddon children="$" />
                                                    <Input placeholder="Monthly Price" value={tier.monthlyPrice} onChange={(e) => setTierValues(index, "monthlyPrice", Number(e.target.value))} />
                                                    <InputRightAddon children=".00" />
                                                </InputGroup>
                                            </div>
                                            <div>
                                                <Text fontSize="sm" textAlign="start">Quarterly Price: </Text>
                                                <InputGroup mb={2}>
                                                    <InputLeftAddon children="$" />
                                                    <Input placeholder="Quarterly Price" value={tier.quarterlyPrice} onChange={(e) => setTierValues(index, "quarterlyPrice", Number(e.target.value))} />
                                                    <InputRightAddon children=".00" />
                                                </InputGroup>
                                            </div>
                                            <div>
                                                <Text fontSize="sm" textAlign="start">Yearly Price: </Text>
                                                <InputGroup mb={2}>
                                                    <InputLeftAddon children="$" />
                                                    <Input placeholder="Yearly Price" value={tier.yearlyPrice} onChange={(e) => setTierValues(index, "yearlyPrice", Number(e.target.value))} />
                                                    <InputRightAddon children=".00" />
                                                </InputGroup>
                                            </div>
                                        </div>
                                        {index === tiers.length - 1 && (
                                            <Button size="sm" colorScheme="blue" ml={2} onClick={() => addNewFields()}>
                                                +
                                            </Button>
                                        )}
                                    </Flex>
                                </div>
                            ))}

                            <Button colorScheme="blue" onClick={handleCreateProduct} disabled={isLoading}>
                                Create Product
                            </Button>
                        </Stack>
                        {error && (
                            <Box borderWidth="1px" p={3} mt={4} color="red.500">
                                {error}
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <Text fontSize="2xl" mb={3}>All Products</Text>
                        <Stack spacing={3}>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                products && products.map((product: any, index: number) => (
                                    <Box borderWidth="1px" p={3} key={product.id}>
                                        <Text fontWeight="bold">{product.name}</Text>
                                        <Text>{product.description}</Text>
                                        <Text fontWeight="bold">Status: {product.active ? "active" : "inactive"}</Text>
                                        <Flex justifyContent="space-around" alignItems="center" gap={10} pt={5}>
                                            {product.prices && product.prices.map(((price: any) => {
                                                return (
                                                    <Box key={price[0].metadata.tierName}>
                                                        <Text fontWeight="bold">{price[0].metadata.tierName}</Text>
                                                        <Flex direction="column" justifyContent="space-around" alignItems="center" gap={10} pt={5}>
                                                            {price.map((p: any) => {
                                                                const interval = p.lookup_key.includes("month_1") ? "Monthly" : p.lookup_key.includes("month_3") ? "Quarterly" : "Yearly";
                                                                return (
                                                                    <Box key={p.id} style={{ border: "0.5px solid", padding: "10px", borderRadius: "8px" }}>
                                                                        <Text fontWeight="semi-bold">{interval}</Text>
                                                                        <Text>Price: {(p.unit_amount / 100).toFixed(2)} USD</Text>
                                                                        <Button
                                                                            onClick={() => {
                                                                                if (!product.active) {
                                                                                    toast({
                                                                                        title: 'Product is inactive.',
                                                                                        description: "Product is archived please conact sales.",
                                                                                        status: 'error',
                                                                                        duration: 9000,
                                                                                        isClosable: true,
                                                                                    })
                                                                                    return;
                                                                                }
                                                                                handleBuyCancelProduct(p.id, product.id, p.subscriptionId)
                                                                            }}
                                                                            colorScheme={p.isSubscribed ? "blue" : "green"} mt={4}>
                                                                            {p.isSubscribed ? "Cancel" : "Buy"}
                                                                        </Button>
                                                                    </Box>
                                                                )
                                                            })}
                                                        </Flex>
                                                    </Box>
                                                )
                                            }))
                                            }
                                        </Flex>
                                    </Box>
                                ))
                            )}
                        </Stack>
                    </Box>
                </Grid>
            </Box>
        </ChakraProvider>
    );
};

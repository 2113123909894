import { useState } from "react";
import {
    ChakraProvider,
    Box,
    Grid,
    Input,
    Button,
    theme,
    VStack,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { useLocation, useNavigate } from "react-router-dom";
import { apiClient as axios } from "../axiosclient";

export const EmailVerificationPage = () => {
    const BASE_URL = process.env.REACT_APP_SERVER_URL;
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const accountId = location.search.split('=')[1];
    const navigate = useNavigate();

    function generateRandomString(length: number) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}[]|;:,.<>?';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    const createNewAccount = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/customers/create`, {
                email: email,
                continuCustomerId: generateRandomString(12),
                connectedAccountId: accountId
            });
            return response;
        } catch (error) {
            console.log({ error })
        }
    }

    const doesAccountExist = async () => {
        try {
            setIsLoading(true)
            const response = await axios.get(`${BASE_URL}/customers?email=${email}`);
            if (response?.data?.customers?.data?.length < 1) {
                const res = await createNewAccount();
                if (res?.data?.customer?.id) {
                    navigate('/elements', { state: { continuCustomerId: res?.data?.customer?.id, connectedAccountId: accountId } });
                }
            } else {
                navigate('/elements', { state: { continuCustomerId: response?.data?.customers?.data[0]?.id, connectedAccountId: accountId } });
            }
        } catch (error) {
            console.log({ error })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <ChakraProvider theme={theme}>
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3}>
                    <ColorModeSwitcher justifySelf="flex-end" />
                    <VStack spacing={8}>
                        <Input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            mb={4}
                            autoFocus
                            width="40%"
                        />
                        <Button
                            colorScheme="blue"
                            onClick={doesAccountExist}
                            isLoading={isLoading}
                            loadingText="Verifying..."
                        >
                            Verify Email
                        </Button>
                    </VStack>
                </Grid>
            </Box>
        </ChakraProvider>
    );
};

import { useElements, useStripe, AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { Box, Button, Grid, Input, Text, GridItem } from '@chakra-ui/react';

export type Props = {
    clientSecret: string;
};

const SetupForm: React.FC<Props> = ({ clientSecret }) => {
    const [loading, setLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [error, setError] = useState("");
    const [currentPyamentType, setCurrentPyamentType] = useState("card");
    const stripe = useStripe();
    const elements = useElements();

    const CLIENT_URL = window.location.origin;

    useEffect(() => {
        if (!elements) return;
        const paymentElement = elements.getElement(PaymentElement);
        if (!paymentElement) return;
        //@ts-ignore
        paymentElement.on('change', (event) => {
            setIsComplete(event.complete);
            setCurrentPyamentType(event?.value?.type)
        });
        return () => {
            //@ts-ignore
            paymentElement.off('change');
        };
    }, [elements]);

    const handleAddNewCard = async () => {
        if (!stripe || !elements) return;

        const paymentElementRef = elements.getElement(PaymentElement);
        const addressElementRef = elements.getElement(AddressElement);
        const address = await addressElementRef?.getValue()
        if (!address?.complete) return;

        if (!paymentElementRef) return;

        try {
            setLoading(true);
            await elements.submit()
            const { error } = await stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: `${CLIENT_URL}/payment-setup-success`
                }
            })

            console.log("ERROR: ", error);
            if (error) {
                setError(error.message as string);
                setLoading(false);
            }

        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleAddNewCard();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <Grid gap={2}>

                    <GridItem colSpan={{ base: 12 }}>
                        <Text variant="body2">Card Details*</Text>
                        <Box>
                            <PaymentElement />
                        </Box>
                    </GridItem>

                    <GridItem colSpan={{ base: 12 }}>
                        <Text variant="body2">Billing Address</Text>
                        <Box>
                            <AddressElement options={{
                                mode: "billing",
                                validation: {
                                    phone: {
                                        required: 'always',
                                    },
                                },
                                fields: {
                                    phone: "always",
                                }
                            }} />
                        </Box>
                    </GridItem>
                </Grid>
                <Button
                    colorScheme="blue"
                    type="submit"
                    isLoading={loading}
                    loadingText="Processing..."
                    isDisabled={!isComplete}
                >
                    Save {currentPyamentType === 'card' ? "card" : currentPyamentType === "link" ? "link" : "bank"}
                </Button>
            </Box>
            {error && <p>{error}</p>}
        </form>
    );
};

export default SetupForm;


import React from 'react';

interface ResetButtonProps {
  onClick: () => void;
}

const ResetButton: React.FC<ResetButtonProps> = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    Reset
  </button>
);

export default ResetButton;

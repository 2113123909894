import { useEffect, useState } from "react";
import {
    ChakraProvider,
    Box,
    Grid,
    Input,
    Button,
    theme,
    Flex,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { useLocation, useNavigate } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { apiClient as axios } from "../axiosclient";
import { SetupForm } from "../components";

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY!;

export const Element = () => {
    const [clientSecret, setClientSecret] = useState<string>("")
    const BASE_URL = process.env.REACT_APP_SERVER_URL;
    const location = useLocation();
    const navigate = useNavigate();

    const stripePromise = loadStripe(STRIPE_PUBLIC_KEY, {
        stripeAccount: location?.state?.connectedAccountId,
    });

    const options = {
        clientSecret: "",
        appearance: {},
    };

    useEffect(() => {
        const accountId = localStorage.getItem("accountId")
        if (location?.state?.connectedAccountId
            && location?.state?.continuCustomerId) {
            localStorage.setItem("accountId", location?.state?.connectedAccountId ?? '');
        } else if (accountId) {
            navigate(`/create-client?accountId=${accountId}`);
        } else {
            localStorage.setItem("accountId", '');
            navigate('/');
        }
    }, [])



    const createIntent = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/payments/create-intent`, {
                continuCustomerId: location?.state?.continuCustomerId ?? "",
                connectedAccountId: location?.state?.connectedAccountId ?? "",
            })
            setClientSecret(response?.data?.customer?.client_secret)
            options.clientSecret = response?.data?.customer?.client_secret;
        } catch (error) {
            console.log({ error })
        }
    }

    useEffect(() => {
        createIntent()
    }, [])


    return (
        <ChakraProvider theme={theme}>
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3}>
                    <ColorModeSwitcher justifySelf="flex-end" />
                    {clientSecret && (
                        <Flex w="100%" justifyContent="center">
                            <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                                <SetupForm clientSecret={clientSecret} />
                            </Elements>
                        </Flex>
                    )}
                </Grid>
            </Box>
        </ChakraProvider>
    );
};

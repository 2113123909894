import { useState } from "react";
import { PaymentMethod } from "../types";
import { apiClient as axios } from "../axiosclient";
import { Box, Button, FormControl, FormLabel, Heading, Text, Flex, Input } from '@chakra-ui/react';
import CustomInput from "./Input";
import AddressInputs from "./AddressInputs";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

type PaymentMethodDetailsProps = {
    paymentMethod: PaymentMethod;
    updateCardExpiry: (paymentMethodId: string, expMonth: number, expYear: number) => void;
    length: number;
    customerId: string;
    accountId: string;
    defaultPaymentMethod: string;
    expiryUpdated: string;
};

const PaymentMethodDetails: React.FC<PaymentMethodDetailsProps> = ({
    paymentMethod,
    updateCardExpiry,
    length,
    customerId,
    accountId,
    defaultPaymentMethod,
    expiryUpdated
}) => {
    const [expMonth, setExpMonth] = useState<string>(paymentMethod?.card?.exp_month.toString() || '');
    const [expYear, setExpYear] = useState<string>(paymentMethod?.card?.exp_year.toString() || '');
    const [city, setCity] = useState<string>(paymentMethod?.billing_details?.address?.city || '');
    const [country, setCountry] = useState<string>(paymentMethod?.billing_details?.address?.country || '');
    const [addressLine1, setAddressLine1] = useState<string>(paymentMethod?.billing_details?.address?.line1 || '');
    const [addressLine2, setAddressLine2] = useState<string>(paymentMethod?.billing_details?.address?.line2 || '');
    const [postalCode, setPostalCode] = useState<string>(paymentMethod?.billing_details?.address?.postal_code || '');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        updateCardExpiry(paymentMethod.id, parseInt(expMonth), parseInt(expYear));
    };

    const handleMakeDefault = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/customers/setDefaultPaymentMethod`, {
                customerId,
                connectedAccountId: accountId ?? "",
                paymentMethodId: paymentMethod.id ?? "",
            })
            window.location.reload();
        } catch (error) {
            console.log({ error })
        }
    }

    const handleRemovePaymentMethod = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/customers/detach-payment-method`, {
                customerId,
                connectedAccountId: accountId ?? "",
                paymentMethodId: paymentMethod.id ?? "",
            })
            window.location.reload();
        } catch (error) {
            console.log({ error })
        }
    }

    const handleUpdateAddress = async () => {
        try {
            const updatedBillingDetails = {
                address: {
                    city,
                    country,
                    line1: addressLine1,
                    line2: addressLine2,
                    postal_code: postalCode,
                },
            };

            const res = await axios.post(
                `${BASE_URL}/customers/update-payment-billing-info/${accountId}/${paymentMethod.id}`,
                updatedBillingDetails
            );
            console.log('Address updated successfully!', res);
        } catch (error) {
            console.error('Error updating address:', error);
        }
    };



    return (
        <Box key={paymentMethod.id} p={4} borderWidth="1px" borderRadius="md">
            <Heading size="md">Billing Info:</Heading>
            <Flex direction="row" justify="space-between">
                {paymentMethod.type === "card" ? (
                    <Flex direction="column" w={'70%'} style={{ textAlign: 'left' }} pt="10">
                        <AddressInputs
                            city={city}
                            setCity={setCity}
                            country={country}
                            setCountry={setCountry}
                            addressLine1={addressLine1}
                            setAddressLine1={setAddressLine1}
                            addressLine2={addressLine2}
                            setAddressLine2={setAddressLine2}
                            postalCode={postalCode}
                            setPostalCode={setPostalCode}
                        />
                        <Text h="60px">Card Number: **** **** **** {paymentMethod?.card?.last4}</Text>
                        <Text h="60px">Card Brand: {paymentMethod?.card?.brand}</Text>
                        <Text h="60px">Card Type: {paymentMethod?.card?.funding}</Text>
                    </Flex>
                ) : paymentMethod.type == "us_bank_account" ? (
                    <Flex direction="column" w={'70%'} style={{ textAlign: 'left' }}>
                        <AddressInputs
                            city={city}
                            setCity={setCity}
                            country={country}
                            setCountry={setCountry}
                            addressLine1={addressLine1}
                            setAddressLine1={setAddressLine1}
                            addressLine2={addressLine2}
                            setAddressLine2={setAddressLine2}
                            postalCode={postalCode}
                            setPostalCode={setPostalCode}
                        />
                        <Text h="60px">Account Type: {paymentMethod?.us_bank_account?.account_type}</Text>
                        <Text h="60px">Account Number: **** **** **** {paymentMethod?.us_bank_account?.last4}</Text>
                        <Text h="60px">Routing Number: {paymentMethod?.us_bank_account?.routing_number}</Text>

                    </Flex>
                ) : (
                    <Flex direction="column" w={'70%'} style={{ textAlign: 'left' }}>
                        <AddressInputs
                            city={city}
                            setCity={setCity}
                            country={country}
                            setCountry={setCountry}
                            addressLine1={addressLine1}
                            setAddressLine1={setAddressLine1}
                            addressLine2={addressLine2}
                            setAddressLine2={setAddressLine2}
                            postalCode={postalCode}
                            setPostalCode={setPostalCode}
                        />
                        <Text h="60px" textTransform="capitalize">Payment Type: {paymentMethod.type}</Text>
                        <Text h="60px">Email: {paymentMethod?.link?.email}</Text>
                    </Flex>
                )}

                <Flex direction="column" w={'30%'} align='flex-end'>
                    {paymentMethod.id === defaultPaymentMethod ? (
                        <Button size="sm" colorScheme="green" mb={10} w={60}>
                            Default Payment
                        </Button>
                    ) : (
                        <Button size="sm" colorScheme="yellow" mb={10} w={60} onClick={handleMakeDefault}>
                            Make Default
                        </Button>
                    )}
                    <Button size="sm" colorScheme="red" mb={10} w={60} onClick={handleRemovePaymentMethod}>
                        Remove
                    </Button>
                    <Button size="sm" colorScheme="gray" mb={10} w={60} onClick={handleUpdateAddress}>
                        Update Address
                    </Button>
                </Flex>

            </Flex>

            {paymentMethod.card && (
                <form onSubmit={handleSubmit}>
                    <Flex direction="row" alignItems="center" mt={4}>
                        <FormControl>
                            <FormLabel>Expiry Date:</FormLabel>
                            <Flex>
                                <Input
                                    type="text"
                                    name="expMonth"
                                    placeholder="MM"
                                    maxLength={2}
                                    value={expMonth}
                                    onChange={(e) => setExpMonth(e.target.value)}
                                    w="80px"
                                    mr={2}
                                />
                                <Text>/</Text>
                                <Input
                                    type="text"
                                    name="expYear"
                                    placeholder="YY"
                                    maxLength={4}
                                    value={expYear}
                                    onChange={(e) => setExpYear(e.target.value)}
                                    w="80px"
                                    ml={2}
                                />
                            </Flex>
                        </FormControl>
                        <Button type="submit" ml={4} isLoading={expiryUpdated === paymentMethod.id}>Update Expiry</Button>
                    </Flex>
                </form>
            )}
        </Box>
    );
};

export default PaymentMethodDetails;

import React, { useState, useEffect } from 'react';
import { apiClient as axios } from "../axiosclient";
import {
    ChakraProvider,
    Box,
    Grid,
    Button,
    Stack,
    Text,
    Flex,
    Spinner,
    Input,
    Textarea,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    useToast
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { ResponseData, Product } from '../types/tierTypes';
import TopBar from '../components/TopBar';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const Tiers: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<any>([]);
    const [newProductName, setNewProductName] = useState('');
    const [newProductDescription, setNewProductDescription] = useState('');
    const [tiers, setTiers] = useState<{
        [key: string]: [
            {
                unit_amount: number,
                up_to: string,
            }
        ]
    }>({
        "monthly": [
            {
                unit_amount: 0,
                up_to: "0",
            }
        ],
        "yearly": [
            {
                unit_amount: 0,
                up_to: "0",
            }
        ],
        "quarterly": [
            {
                unit_amount: 0,
                up_to: "0",
            }
        ],

    });
    const [error, setError] = useState<string>('');
    const toast = useToast();

    const navigate = useNavigate();

    const connectedAccountId = localStorage.getItem('accountId');
    const customerId = localStorage.getItem('customerId');
    const defaultPaymentMethod = localStorage.getItem('defaultPaymentMethod');

    useEffect(() => {
        getAllProducts();
    }, []);

    const getAllProducts = async () => {
        try {
            setIsLoading(true);
            const productsResponse = await axios.get(`${BASE_URL}/tiered-products/get-tiered-products/${connectedAccountId}/${customerId}`);
            setProducts(productsResponse.data.products);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleBuyCancelProduct = async (priceId: string, productId: string, subscriptionId: string, quantity: number) => {
        try {
            if (subscriptionId) {
                const res = await axios.post(`${BASE_URL}/subscription/cancel`, {
                    subscriptionId,
                    connectedAccountId: connectedAccountId,
                });
                toast({
                    title: 'Subscription cancelled',
                    description: "Your subscription is cancelled.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                if (!defaultPaymentMethod) {
                    // handle error for no default payment method
                    toast({
                        title: 'No default payment method.',
                        description: "Please select default payment method.",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                } else if (connectedAccountId && customerId && !!priceId && !!productId) {
                    const res = await axios.post(`${BASE_URL}/subscription/create`, {
                        priceId: priceId,
                        customerId: customerId,
                        connectedAccountId: connectedAccountId,
                        paymentMethodId: defaultPaymentMethod,
                        productId: productId,
                        quantity
                    });
                    toast({
                        title: 'Payment successfull',
                        description: "Your payment for the product is successfull.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
            getAllProducts();
        } catch (error) {
            toast({
                title: 'Something went wrong',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }

    }

    const checkValues = (array: any) => {
        return array.filter((obj: any) => obj.unit_amount <= 0 || !obj.up_to)?.length >= 1
    }

    const createProduct = async () => {
        try {
            setIsLoading(true);
            setError('');

            if (!newProductName
                || !newProductDescription
                || checkValues(tiers.monthly)
                || checkValues(tiers.quarterly)
                || checkValues(tiers.yearly)
            ) {
                setError('Please fill in all fields.');
                return;
            }

            const monthlyTiers = tiers.monthly.map(((monthly, index) => {
                if (index === (tiers.monthly.length - 1)) {
                    return {
                        unit_amount: monthly.unit_amount * 100,
                        up_to: "inf",
                    }
                }
                return {
                    unit_amount: monthly.unit_amount * 100,
                    up_to: monthly.up_to,
                }
            }));
            const quarterlyTiers = tiers.quarterly.map(((quarterly, index) => {
                if (index === (tiers.quarterly.length - 1)) {
                    return {
                        unit_amount: quarterly.unit_amount * 100,
                        up_to: "inf",
                    }
                }
                return {
                    unit_amount: quarterly.unit_amount * 100,
                    up_to: quarterly.up_to,
                }
            }));
            const yearlyTiers = tiers.yearly.map(((yearly, index) => {
                if (index === (tiers.yearly.length - 1)) {
                    return {
                        unit_amount: yearly.unit_amount * 100,
                        up_to: "inf",
                    }
                }
                return {
                    unit_amount: yearly.unit_amount * 100,
                    up_to: yearly.up_to,
                }
            }));

            const data = await axios.post(`${BASE_URL}/tiered-products/create-tiered-product`, {
                name: newProductName,
                description: newProductDescription,
                monthlyTiers,
                quarterlyTiers,
                yearlyTiers,
                connectedAccountId,
            }).then(() => {
                setNewProductName("");
                setNewProductDescription("");
                setTiers({
                    "monthly": [
                        {
                            unit_amount: 0,
                            up_to: "0",
                        }
                    ],
                    "yearly": [
                        {
                            unit_amount: 0,
                            up_to: "0",
                        }
                    ],
                    "quarterly": [
                        {
                            unit_amount: 0,
                            up_to: "0",
                        }
                    ],
                });
            });
            await getAllProducts();
        } catch (error) {
            console.error('Error creating product:', error);
            setError('Failed to create product. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const setTierValues = ({ interval, index, price, upto }: {
        interval: string,
        index: number,
        price: number,
        upto: string,
    }) => {
        const tempValue = tiers[interval];
        if (!tempValue) return;
        tempValue[index].unit_amount = price;
        let tempUpto = upto;
        if (upto.length >= 1 && upto[0] === "0") {
            tempUpto = upto.substr(1);
        }
        tempValue[index].up_to = tempUpto;
        const tempTiers = { ...tiers, [interval]: tempValue };
        setTiers(tempTiers)
    }

    const addNewFields = (interval: string) => {
        //@ts-ignore
        setTiers({
            ...tiers,
            [interval]: [
                ...tiers[interval],
                {
                    unit_amount: 0,
                    up_to: "0",
                }
            ]
        })
    }

    return (
        <ChakraProvider>
            <TopBar />
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3} templateColumns="3fr 7fr" gap={4}>
                    <Box>
                        <Stack spacing={3} mb={4}>
                            <Input placeholder="Product Name" value={newProductName} onChange={(e) => setNewProductName(e.target.value)} />
                            <Textarea placeholder="Product Description" value={newProductDescription} onChange={(e) => setNewProductDescription(e.target.value)} />
                            <Text fontSize="sm" textAlign="start">Monthly Tiers</Text>
                            {tiers?.monthly?.map((monthlyTier, index) => {
                                return (
                                    <Flex flexDirection="row" gap={4} justifyContent="flex-start" alignItems="center" key={index}>
                                        <div style={{ width: "40%", alignItems: "center" }}>
                                            <Text fontSize="sm" textAlign="start">Price</Text>
                                            <InputGroup>
                                                <InputLeftAddon children="$" />
                                                <Input
                                                    placeholder="Monthly Price"
                                                    value={monthlyTier.unit_amount}
                                                    onChange={(e) =>
                                                        setTierValues({
                                                            interval: "monthly",
                                                            index,
                                                            price: Number(e.target.value),
                                                            upto: monthlyTier.up_to,
                                                        })
                                                    } />
                                                <InputRightAddon children=".00" />
                                            </InputGroup>
                                        </div>
                                        <div style={{ width: "40%", alignItems: "center" }}>
                                            <Text fontSize="sm" textAlign="start">Up to</Text>
                                            <InputGroup>
                                                <Input placeholder="Up to"
                                                    value={monthlyTier.up_to}
                                                    onChange={(e) =>
                                                        setTierValues({
                                                            interval: "monthly",
                                                            index,
                                                            price: monthlyTier.unit_amount,
                                                            upto: e.target.value,
                                                        })
                                                    } />
                                            </InputGroup>
                                        </div>
                                        {index === (tiers?.monthly.length - 1) && (
                                            <Button size="sm" colorScheme="blue" ml={2} onClick={() => addNewFields("monthly")}>
                                                +
                                            </Button>
                                        )}
                                    </Flex>
                                )
                            })}
                            <Text fontSize="sm" textAlign="start">Quarterly Tiers</Text>
                            {tiers?.quarterly?.map((monthlyTier, index) => {
                                return (
                                    <Flex flexDirection="row" gap={4} justifyContent="flex-start" alignItems="center" key={index}>
                                        <div style={{ width: "40%", alignItems: "center" }}>
                                            <Text fontSize="sm" textAlign="start">Price</Text>
                                            <InputGroup>
                                                <InputLeftAddon children="$" />
                                                <Input
                                                    placeholder="quarterly Price"
                                                    value={monthlyTier.unit_amount}
                                                    onChange={(e) =>
                                                        setTierValues({
                                                            interval: "quarterly",
                                                            index,
                                                            price: Number(e.target.value),
                                                            upto: monthlyTier.up_to,
                                                        })
                                                    } />
                                                <InputRightAddon children=".00" />
                                            </InputGroup>
                                        </div>
                                        <div style={{ width: "40%", alignItems: "center" }}>
                                            <Text fontSize="sm" textAlign="start">Up to</Text>
                                            <InputGroup>
                                                <Input placeholder="Up to"
                                                    value={monthlyTier.up_to}
                                                    onChange={(e) =>
                                                        setTierValues({
                                                            interval: "quarterly",
                                                            index,
                                                            price: monthlyTier.unit_amount,
                                                            upto: e.target.value,
                                                        })
                                                    } />
                                            </InputGroup>
                                        </div>
                                        {index === (tiers?.quarterly.length - 1) && (
                                            <Button size="sm" colorScheme="blue" ml={2} onClick={() => addNewFields("quarterly")}>
                                                +
                                            </Button>
                                        )}
                                    </Flex>
                                )
                            })}
                            <Text fontSize="sm" textAlign="start">Yearly Tiers</Text>
                            {tiers?.yearly?.map((monthlyTier, index) => {
                                return (
                                    <Flex flexDirection="row" gap={4} justifyContent="flex-start" alignItems="center" key={index}>
                                        <div style={{ width: "40%", alignItems: "center" }}>
                                            <Text fontSize="sm" textAlign="start">Price</Text>
                                            <InputGroup>
                                                <InputLeftAddon children="$" />
                                                <Input
                                                    placeholder="Yearly Price"
                                                    value={monthlyTier.unit_amount}
                                                    onChange={(e) =>
                                                        setTierValues({
                                                            interval: "yearly",
                                                            index,
                                                            price: Number(e.target.value),
                                                            upto: monthlyTier.up_to,
                                                        })
                                                    } />
                                                <InputRightAddon children=".00" />
                                            </InputGroup>
                                        </div>
                                        <div style={{ width: "40%", alignItems: "center" }}>
                                            <Text fontSize="sm" textAlign="start">Up to</Text>
                                            <InputGroup>
                                                <Input placeholder="Up to"
                                                    value={monthlyTier.up_to}
                                                    onChange={(e) =>
                                                        setTierValues({
                                                            interval: "yearly",
                                                            index,
                                                            price: monthlyTier.unit_amount,
                                                            upto: e.target.value,
                                                        })
                                                    } />
                                            </InputGroup>
                                        </div>
                                        {index === (tiers?.yearly.length - 1) && (
                                            <Button size="sm" colorScheme="blue" ml={2} onClick={() => addNewFields("yearly")}>
                                                +
                                            </Button>
                                        )}
                                    </Flex>
                                )
                            })}
                            <Button colorScheme="blue" onClick={createProduct} disabled={isLoading}>
                                Create Product
                            </Button>
                        </Stack>
                        {error && (
                            <Box borderWidth="1px" p={3} mt={4} color="red.500">
                                {error}
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <Text fontSize="2xl" mb={3}>All Products</Text>
                        <Stack spacing={3}>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                products && products.map((product: any, productIndex: number) => (
                                    <Box borderWidth="1px" p={3} key={product.id}>
                                        <Text fontWeight="bold">{product.name}</Text>
                                        <Text>{product.description}</Text>
                                        <Text fontWeight="bold">Status: {product.active ? "active" : "inactive"}</Text>
                                        <Flex justifyContent="center" alignItems="center" gap={10} mt={10}>
                                            {product.prices.map((price: any, priceIndex: number) => {
                                                const interval = price.lookup_key.includes("monthly_1") ? "Monthly" : price.lookup_key.includes("monthly_3") ? "Quarterly" : "Yearly";
                                                return (
                                                    <Flex flexDirection="column" borderColor={"black"} border={1} borderWidth={"1px"} key={interval + Math.random()} justifyContent="space-between">
                                                        <div style={{ height: "300px", overflowY: "auto" }}>
                                                            <Text fontWeight="bold">{interval}</Text>
                                                            {price.tiers.map((tier: any, index: number) => {
                                                                const tierQuantity = tier.up_to ? tier.up_to : price.tiers[index - 1] ? price.tiers[index - 1]?.up_to + 1 : "Infinty";
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            border: "1px solid",
                                                                            marginBottom: "5px",
                                                                            borderRadius: "8px",
                                                                            padding: "2px",
                                                                            background: !tier.isSubscribed ? "white" : "green",
                                                                            color: tier.isSubscribed ? "white" : "black"
                                                                        }}
                                                                        key={tier.id}
                                                                    >
                                                                        <Text fontWeight="bold">{`${tier.up_to ?? "Infinity"}`}</Text>
                                                                        <Text>Price: {(tier.unit_amount / 100).toFixed(2)} USD</Text>
                                                                        {tier.isSubscribed && <Text>Subscribed</Text>}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <Text fontSize="sm" textAlign="start">Quantity: </Text>
                                                                <InputGroup>
                                                                    <Input
                                                                        placeholder="Quantity"
                                                                        type='number'
                                                                        value={products[productIndex].prices[priceIndex].selectedValue}
                                                                        onChange={(e) => {
                                                                            const quan = Number(e.target.value);
                                                                            const prods = products;
                                                                            prods[productIndex].prices[priceIndex].selectedValue = quan;
                                                                            setProducts([...prods])
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </div>
                                                            <Button
                                                                onClick={() => {
                                                                    const currentTiers = products[productIndex].prices[priceIndex].tiers;
                                                                    let quan = products[productIndex].prices[priceIndex].selectedValue;
                                                                    quan = Number(quan) ? Number(quan) : Number(currentTiers[0].up_to);
                                                                    handleBuyCancelProduct(price.id, product.id, price.subscriptionId, quan)
                                                                }}
                                                                colorScheme={product.active ? "green" : "gray"}
                                                                mt={4}
                                                                disabled={!product.active}
                                                            >
                                                                {"Buy"}
                                                            </Button>
                                                        </div>
                                                    </Flex>
                                                )
                                            })}

                                        </Flex>
                                    </Box>
                                ))
                            )}
                        </Stack>
                    </Box>
                </Grid>
            </Box>
        </ChakraProvider>
    );
};

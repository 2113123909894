import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
});

apiClient.interceptors.request.use(
  async (config) => {
    config.headers.set(
      'authorization',
      `V1 ${process.env.REACT_APP_STRIPE_ADAPTER_SHARED_KEY}`,
    );

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export { apiClient };

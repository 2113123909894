import { Box, Text } from "@chakra-ui/react";
import { CustomerType } from "../types";

type CustomerProps = {
    customer: CustomerType;
    handleSelectUser: (customerId: string) => void;
};

const Customer: React.FC<CustomerProps> = ({ customer, handleSelectUser }) => (
    <Box
        key={customer.id}
        p={3}
        cursor="pointer"
        borderRadius="md"
        onClick={() => handleSelectUser(customer.id)}
        _hover={{ bg: 'gray.100' }}
    >
        <Text>{customer.email}</Text>
    </Box>
);

export default Customer;
import React, { useState, useEffect } from 'react';
import { apiClient as axios } from "../axiosclient";
import {
    ChakraProvider,
    Box,
    Grid,
    Button,
    Stack,
    Text,
    Flex,
    Spinner,
    Input,
    Textarea,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    useToast
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { PaymentIntent, Product } from '../types';
import TopBar from '../components/TopBar';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const Products: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [newProductName, setNewProductName] = useState('');
    const [newProductDescription, setNewProductDescription] = useState('');
    const [newProductPrice, setNewProductPrice] = useState('');
    const [error, setError] = useState<string>('');
    const toast = useToast();

    const navigate = useNavigate();

    const connectedAccountId = localStorage.getItem('accountId');
    const customerId = localStorage.getItem('customerId');
    const defaultPaymentMethod = localStorage.getItem('defaultPaymentMethod');

    useEffect(() => {
        getAllProducts();
    }, []);

    const getAllProducts = async () => {
        try {
            setIsLoading(true);
            const productsResponse = await axios.get(`${BASE_URL}/products/get-products/${connectedAccountId}`);
            const fetchedProducts = productsResponse.data.products;
            const productsWithPrices = await Promise.all(
                fetchedProducts.filter((p: any) => p.default_price).map(async (product: any) => {
                    if (!!product.default_price && product.default_price !== 'null') {
                        const pricesResponse = await axios.get(`${BASE_URL}/products/get-price/${product.default_price}/${connectedAccountId}`);
                        const price = pricesResponse.data.price;
                        return { ...product, price: price?.unit_amount / 100 ?? 0 };
                    }
                    return { ...product, price: 0 };
                })
            );
            setProducts(productsWithPrices);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBuyProduct = async (amount: number) => {
        if (!defaultPaymentMethod) {
            // handle error for no default payment method
            toast({
                title: 'No default payment method.',
                description: "Please select default payment method.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } else if (connectedAccountId && customerId && amount >= 0) {
            await axios.post<PaymentIntent>(`${BASE_URL}/payments/one-time-payment`, {
                amount: amount * 100,
                continuCustomerId: customerId,
                connectedAccountId: connectedAccountId,
                paymentMethodId: defaultPaymentMethod,
            });
            toast({
                title: 'Payment successfull',
                description: "Your payment for the product is successfull.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const createProduct = async () => {
        try {
            setIsLoading(true);
            setError('');

            if (!newProductName || !newProductDescription || !newProductPrice) {
                setError('Please fill in all fields.');
                return;
            }

            const priceValue = Number(newProductPrice);
            if (isNaN(priceValue) || priceValue <= 0) {
                setError('Please enter a valid price.');
                return;
            }

            await axios.post(`${BASE_URL}/products`, {
                name: newProductName,
                description: newProductDescription,
                priceInCents: priceValue * 100,
                connectedAccountId,
            }).then(() => {
                setNewProductName("");
                setNewProductDescription("");
                setNewProductPrice("")
            });

            await getAllProducts();
        } catch (error) {
            console.error('Error creating product:', error);
            setError('Failed to create product. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <ChakraProvider>
            <TopBar />
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3} templateColumns="3fr 7fr" gap={4}>
                    <Box>
                        <Stack spacing={3} mb={4}>
                            <Input placeholder="Product Name" value={newProductName} onChange={(e) => setNewProductName(e.target.value)} />
                            <Textarea placeholder="Product Description" value={newProductDescription} onChange={(e) => setNewProductDescription(e.target.value)} />
                            <InputGroup>
                                <InputLeftAddon children="$" />
                                <Input placeholder="Price" value={newProductPrice} onChange={(e) => setNewProductPrice(e.target.value)} />
                                <InputRightAddon children=".00" />
                            </InputGroup>
                            <Button colorScheme="blue" onClick={createProduct} disabled={isLoading}>
                                Create Product
                            </Button>
                        </Stack>
                        {error && (
                            <Box borderWidth="1px" p={3} mt={4} color="red.500">
                                {error}
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <Text fontSize="2xl" mb={3}>All Products</Text>
                        <Stack spacing={3}>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                products.map((product, index) => (
                                    <Box key={index} borderWidth="1px" p={3}>
                                        <Text fontWeight="bold">{product.name}</Text>
                                        <Text>{product.description}</Text>
                                        <Text fontWeight="bold">Status: {product.active ? "active" : "inactive"}</Text>
                                        <Text fontWeight="bold" mt={2}>Price:</Text>
                                        <Text>{product.price} USD</Text>

                                        <Button
                                            onClick={() => {
                                                if (!product.active) {
                                                    toast({
                                                        title: 'Product is inactive.',
                                                        description: "Product is archived please conact sales.",
                                                        status: 'error',
                                                        duration: 9000,
                                                        isClosable: true,
                                                    })
                                                    return;
                                                }
                                                handleBuyProduct(product.price)
                                            }}
                                            colorScheme="blue"
                                            mt={4}>
                                            Buy
                                        </Button>
                                    </Box>
                                ))
                            )}
                        </Stack>
                    </Box>
                </Grid>
            </Box>
        </ChakraProvider>
    );
};

import React, { useState, useEffect } from 'react';
import { apiClient as axios } from "../axiosclient";
import {
    ChakraProvider,
    Box,
    Grid,
    Button,
    Stack,
    Text,
    Flex,
    Spinner,
    Input,
    Textarea,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    useToast
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { PaymentIntent, Product, StripeSubscription } from '../types';
import TopBar from '../components/TopBar';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const Subscription: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<any>([]);
    const [newProductName, setNewProductName] = useState('');
    const [newProductDescription, setNewProductDescription] = useState('');
    const [monthlyPrice, setMonthlyPrice] = useState('');
    const [quarterly, setQuarterly] = useState('');
    const [yearly, setYearly] = useState('');
    const [error, setError] = useState<string>('');
    const toast = useToast();

    const navigate = useNavigate();

    const connectedAccountId = localStorage.getItem('accountId');
    const customerId = localStorage.getItem('customerId');
    const defaultPaymentMethod = localStorage.getItem('defaultPaymentMethod');

    useEffect(() => {
        getAllProducts();
    }, []);

    const getAllProducts = async () => {
        try {
            setIsLoading(true);
            const productsResponse = await axios.get(`${BASE_URL}/flat-rate-products/get-products/${connectedAccountId}/${customerId}`);
            console.log(productsResponse);
            setProducts(productsResponse.data.products);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleBuyCancelProduct = async (priceId: string, productId: string, subscriptionId: string) => {
        try {
            if (subscriptionId) {
                const res = await axios.post(`${BASE_URL}/subscription/cancel`, {
                    subscriptionId,
                    connectedAccountId: connectedAccountId,
                });
                toast({
                    title: 'Subscription cancelled',
                    description: "Your subscription is cancelled.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                if (!defaultPaymentMethod) {
                    // handle error for no default payment method
                    toast({
                        title: 'No default payment method.',
                        description: "Please select default payment method.",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                } else if (connectedAccountId && customerId && !!priceId && !!productId) {
                    const res = await axios.post(`${BASE_URL}/subscription/create`, {
                        priceId: priceId,
                        customerId: customerId,
                        connectedAccountId: connectedAccountId,
                        paymentMethodId: defaultPaymentMethod,
                        productId: productId
                    });
                    toast({
                        title: 'Payment successfull',
                        description: "Your payment for the product is successfull.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
            getAllProducts();
        } catch (error) {
            toast({
                title: 'Something went wrong',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }

    }

    const createProduct = async () => {
        try {
            setIsLoading(true);
            setError('');

            if (!newProductName || !newProductDescription || !monthlyPrice || !quarterly || !yearly) {
                setError('Please fill in all fields.');
                return;
            }

            const monthlyValue = Number(monthlyPrice);
            const quarterlyValue = Number(quarterly);
            const yearlyValue = Number(yearly);
            if (isNaN(monthlyValue) || monthlyValue <= 0 || isNaN(quarterlyValue) || quarterlyValue <= 0 || isNaN(yearlyValue) || yearlyValue <= 0) {
                setError('Please enter a valid price.');
                return;
            }

            await axios.post(`${BASE_URL}/flat-rate-products/create-product`, {
                name: newProductName,
                description: newProductDescription,
                monthlyPriceInCents: monthlyValue * 100,
                quarterlyPriceInCents: quarterlyValue * 100,
                yearlyPriceInCents: yearlyValue * 100,
                connectedAccountId,
            }).then(() => {
                setMonthlyPrice("");
                setQuarterly("");
                setYearly("");
                setNewProductName("");
                setNewProductDescription("");
            });
            await getAllProducts();
        } catch (error) {
            console.error('Error creating product:', error);
            setError('Failed to create product. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ChakraProvider>
            <TopBar />
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3} templateColumns="3fr 7fr" gap={4}>
                    <Box>
                        <Stack spacing={3} mb={4}>
                            <Input placeholder="Product Name" value={newProductName} onChange={(e) => setNewProductName(e.target.value)} />
                            <Textarea placeholder="Product Description" value={newProductDescription} onChange={(e) => setNewProductDescription(e.target.value)} />
                            <InputGroup>
                                <InputLeftAddon children="$" />
                                <Input placeholder="Monthly Price" value={monthlyPrice} onChange={(e) => setMonthlyPrice(e.target.value)} />
                                <InputRightAddon children=".00" />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftAddon children="$" />
                                <Input placeholder="Quarterly Price" value={quarterly} onChange={(e) => setQuarterly(e.target.value)} />
                                <InputRightAddon children=".00" />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftAddon children="$" />
                                <Input placeholder="Yearly Price" value={yearly} onChange={(e) => setYearly(e.target.value)} />
                                <InputRightAddon children=".00" />
                            </InputGroup>
                            <Button colorScheme="blue" onClick={createProduct} disabled={isLoading}>
                                Create Product
                            </Button>
                        </Stack>
                        {error && (
                            <Box borderWidth="1px" p={3} mt={4} color="red.500">
                                {error}
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <Text fontSize="2xl" mb={3}>All Products</Text>
                        <Stack spacing={3}>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                products && products.map((product: any, index: any) => (
                                    <Box borderWidth="1px" p={3} key={product.id}>
                                        <Text fontWeight="bold">{product.name}</Text>
                                        <Text>{product.description}</Text>
                                        <Text fontWeight="bold">Status: {product.active ? "active" : "inactive"}</Text>
                                        <Flex justifyContent="center" alignItems="center" gap={10}>
                                            {product.prices.map((price: any) => {
                                                const interval = (price.recurring.interval === "month" && price.recurring.interval_count > 1) ? "Quarterly Subscription" :
                                                    price.recurring.interval === "month"
                                                        ? "Monhtly Subscription"
                                                        : "Yearly Subscription";
                                                return (
                                                    <Flex flexDirection="column" borderColor={"black"} border={1} borderWidth={"1px"} key={price.id}>
                                                        <Text fontWeight="bold">{interval}</Text>
                                                        <Text>{`Duration ${price.recurring.interval_count} ${price.recurring.interval}`}</Text>
                                                        <Text>Price: {price.unit_amount / 100} USD</Text>
                                                        <Button onClick={() => {
                                                            if (!product.active) {
                                                                toast({
                                                                    title: 'Product is inactive.',
                                                                    description: "Product is archived please conact sales.",
                                                                    status: 'error',
                                                                    duration: 9000,
                                                                    isClosable: true,
                                                                })
                                                                return;
                                                            }
                                                            handleBuyCancelProduct(price.id, product.id, price.subscriptionId)
                                                        }} colorScheme={price.isSubscribed ? "blue" : "green"} mt={4}>
                                                            {price.isSubscribed ? "Cancel" : "Buy"}
                                                        </Button>
                                                    </Flex>
                                                )
                                            })}

                                        </Flex>
                                    </Box>
                                ))
                            )}
                        </Stack>
                    </Box>
                </Grid>
            </Box>
        </ChakraProvider>
    );
};

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from "./screens/home";
import { SuccessPage } from './screens/success';
import { EmailVerificationPage } from './screens/getClient';
import { Element } from './screens/elements';
import { PaymentSuccess } from './screens/payment-success';
import { Products } from './screens/products';
import { Subscription } from './screens/subscription';
import { Tiers } from './screens/tiers';
import { GoodBetterBest } from './screens/good-better-best';
// import dotenv from 'dotenv';
// dotenv.config({ path: '../.env' });

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/create-client" element={<EmailVerificationPage />} />
        <Route path="/elements" element={<Element />} />
        <Route path="/payment-setup-success" element={<PaymentSuccess />} />
        <Route path="/products" element={<Products />} />
        <Route path="/subscriptions" element={<Subscription />} />
        <Route path="/tiers" element={<Tiers />} />
        <Route path="/good-better-best" element={<GoodBetterBest />} />
      </Routes>
    </Router>
  )
}

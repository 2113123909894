import React, { useState, useEffect } from 'react';
import { apiClient as axios } from "../axiosclient";
import {
  ChakraProvider,
  Box,
  Grid,
  Button,
  Stack,
  Text,
  Flex,
  Spinner,
  InputGroup,
  Input
} from '@chakra-ui/react';

import { CustomerType, PaymentMethod } from '../types';
import { useNavigate } from 'react-router-dom';
import { Customer, PaymentMethodDetails } from '../components';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

type AccountIds = {
  continuCustomerId: string;
  connectedAccountId: string;
};

export const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expiryUpdated, setExpiryUpdated] = useState<string>("");
  const [customers, setCustomers] = useState<CustomerType[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [selectedCustomerPaymentMethods, setSelectedCustomerPaymentMethods] = useState<
    PaymentMethod[] | null
  >(null);
  const [accountId, setAccountId] = useState<string>('');
  const [accountIdState, setAccountIdState] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const accountIdsLocalStorage = localStorage.getItem('accountId');
    if (!accountIdsLocalStorage) {
      setAccountId("");
      setIsLoading(false);
      return;
    }
    setAccountId(accountIdsLocalStorage);
    getCustomers(accountIdsLocalStorage);
  }, []);

  useEffect(() => {
    if (!!selectedCustomer) {
      getCustomerPaymentMethods({ connectedAccountId: accountId, continuCustomerId: selectedCustomer });
    }
  }, [selectedCustomer]);

  const handleSelectUser = (customerId: string) => {
    if (customerId === selectedCustomer) {
      setSelectedCustomer('');
      setSelectedCustomerPaymentMethods(null);
      return;
    }
    setSelectedCustomer(customerId);
  };

  // console.log({ selectedCustomerPaymentMethods })

  const updateCardExpiry = async (paymentMethodId: string, expMonth: number, expYear: number) => {
    try {
      setExpiryUpdated(paymentMethodId);
      const response = await axios.put(`${BASE_URL}/customers/update-payment-method-expiry/${paymentMethodId}/${accountId}`, {
        expMonth,
        expYear,
      });
      console.log(response)
      setExpiryUpdated("");
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getCustomers = async (connectedAccountId: string) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/customers/connected-account-customers/${connectedAccountId}`
      );
      setCustomers(response?.data?.customers?.data || []);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getCustomerPaymentMethods = async ({ continuCustomerId, connectedAccountId }: AccountIds) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/customers/retrieve-payment-methods/${connectedAccountId}/${continuCustomerId}`
      );
      console.log(response?.data?.paymentMethods?.data);
      const pms = response?.data?.paymentMethods?.data;
      const defaultPaymentMethod = customers.find((c) => c.id === selectedCustomer)?.invoice_settings?.default_payment_method;

      if (pms.length === 1 && !defaultPaymentMethod) {
        const pm = await axios.post(`${BASE_URL}/customers/setDefaultPaymentMethod`, {
          customerId: continuCustomerId,
          connectedAccountId: accountId ?? "",
          paymentMethodId: pms[0].id ?? "",
        })
        getCustomers(connectedAccountId);
      } 
      setSelectedCustomerPaymentMethods(response?.data?.paymentMethods?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/users/onboard-user`);
      window.location.href = response.data.url;
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <ChakraProvider>
      {!isLoading ? (!accountId ? (
        <Flex height="100vh" alignItems="center" justifyContent="center" direction="column">
          <div>
            <Text fontSize="sm" textAlign="start">Account ID: </Text>
            <InputGroup>
              <Input
                placeholder="Account Id"
                value={accountIdState}
                onChange={(e) => {
                  setAccountIdState(e.target.value)
                }}
              />
            </InputGroup>
            <Button onClick={() => {
              if (!accountIdState) return;
              localStorage.setItem("accountId", accountIdState);
              window.location.reload();
            }} colorScheme="blue" mt={4}>
              Use Existing Accoutn ID
            </Button>
          </div>

          <Button onClick={handleClick} colorScheme="yellow" mt={4}>
            Setup Connected Account
          </Button>
        </Flex>
      ) : (
        <Box textAlign="center" fontSize="xl">
          <Grid minH="100vh" p={3} templateColumns="3fr 7fr" gap={4}>
            <Stack spacing={4} direction="column" alignItems="center" gridColumn="1">
              {customers.length === 0 ? (
                <Text>No users available</Text>
              ) : (
                customers.map((customer) => {
                  return (
                    <Customer
                      key={customer.id}
                      customer={customer}
                      handleSelectUser={handleSelectUser}
                    />)
                })
              )}
            </Stack>
            <Stack spacing={6} p={4} borderRadius="md" bg="gray.50" gridColumn="2">
              {!selectedCustomer ? (
                <Text>No user selected</Text>
              ) : (
                <Stack spacing={4}>
                  <Flex flexDirection="row">
                    <Button size="sm" colorScheme="blue" mb={10} w={60} mr={2} onClick={() => {
                      navigate('/elements', { state: { continuCustomerId: selectedCustomer, connectedAccountId: accountId } });
                    }}>
                      Make new payment method
                    </Button>
                    <Button size="sm" colorScheme="blue" mb={10} w={60} mr={2} onClick={() => {
                      const defaultPaymentMethod = customers.find((c) => c.id === selectedCustomer)?.invoice_settings?.default_payment_method;
                      localStorage.setItem("customerId", selectedCustomer);
                      localStorage.setItem("defaultPaymentMethod", defaultPaymentMethod ?? '');
                      navigate('/products');
                    }}>
                      One time purchase
                    </Button>
                    <Button size="sm" colorScheme="blue" mb={10} w={60} mr={2} onClick={() => {
                      const defaultPaymentMethod = customers.find((c) => c.id === selectedCustomer)?.invoice_settings?.default_payment_method;
                      localStorage.setItem("customerId", selectedCustomer);
                      localStorage.setItem("defaultPaymentMethod", defaultPaymentMethod ?? '');
                      navigate('/subscriptions');
                    }}>
                      Flat rate subscriptions
                    </Button>
                    <Button size="sm" colorScheme="blue" mb={10} w={60} mr={2} onClick={() => {
                      const defaultPaymentMethod = customers.find((c) => c.id === selectedCustomer)?.invoice_settings?.default_payment_method;
                      localStorage.setItem("customerId", selectedCustomer);
                      localStorage.setItem("defaultPaymentMethod", defaultPaymentMethod ?? '');
                      navigate('/tiers');
                    }}>
                      Tiered Per seat subscriptions
                    </Button>
                    <Button size="sm" colorScheme="blue" mb={10} w={60} onClick={() => {
                      const defaultPaymentMethod = customers.find((c) => c.id === selectedCustomer)?.invoice_settings?.default_payment_method;
                      localStorage.setItem("customerId", selectedCustomer);
                      localStorage.setItem("defaultPaymentMethod", defaultPaymentMethod ?? '');
                      navigate('/good-better-best');
                    }}>
                      Good Better Best subscriptions
                    </Button>
                  </Flex>
                  {selectedCustomerPaymentMethods?.map((paymentMethod) => (
                    <PaymentMethodDetails
                      expiryUpdated={expiryUpdated}
                      key={paymentMethod.id}
                      paymentMethod={paymentMethod}
                      updateCardExpiry={updateCardExpiry}
                      length={selectedCustomerPaymentMethods.length}
                      customerId={selectedCustomer}
                      accountId={accountId}
                      defaultPaymentMethod={customers.find((c) => c.id === selectedCustomer)?.invoice_settings?.default_payment_method as string}
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          </Grid>
        </Box>)) : (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      )}

    </ChakraProvider >
  );
};

import React from 'react';

interface SubmitButtonProps {
  processing: boolean;
  error: any;
  disabled: boolean;
  children: React.ReactNode;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  processing,
  error,
  children,
  disabled,
}) => (
  <button
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? 'Processing...' : children}
  </button>
);

export default SubmitButton;

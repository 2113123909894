// @ts-nocheck

import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Input, Text } from '@chakra-ui/react';

type AddressInputProps = {
    label: string;
    value: string;
    onChange: (addressData: {
        selectedCountry: string;
        selectedCity: string;
        selectedState: string;
        selectedZip: string;
        selectedLine1: string;
        selectedLine2: string;
    }) => void;
};

const AddressInput: React.FC<AddressInputProps> = ({ label, value, onChange }) => {
    const handleSelect = async (address: string) => {
        try {
            const results = await geocodeByAddress(address);
            const { address_components } = results[0];
            let selectedCountry = '';
            let selectedCity = '';
            let selectedState = '';
            let selectedZip = '';
            let selectedLine1 = '';
            let selectedLine2 = '';

            address_components.forEach(component => {
                const componentType = component.types[0];
                switch (componentType) {
                    case 'country':
                        selectedCountry = component.long_name;
                        break;
                    case 'locality':
                        selectedCity = component.long_name;
                        break;
                    case 'administrative_area_level_1':
                        selectedState = component.short_name;
                        break;
                    case 'postal_code':
                        selectedZip = component.long_name;
                        break;
                    case 'street_number':
                        selectedLine1 = component.long_name;
                        break;
                    case 'route':
                        selectedLine2 = component.long_name;
                        break;
                    default:
                        break;
                }
            });

            onChange({
                selectedCountry,
                selectedCity,
                selectedState,
                selectedZip,
                selectedLine1,
                selectedLine2
            });
        } catch (error) {
            console.error('Error', error);
        }
    };

    return (
        <PlacesAutocomplete
            value={value}
            onChange={onChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <Text w={"20%"}>{label}: </Text>
                    <Input
                        {...getInputProps({
                            placeholder: 'Enter an address',
                            className: 'location-search-input',
                        })}
                        w={"70%"}
                    />
                    <div>
                        {loading ? <div>Loading...</div> : null}

                        {suggestions.map((suggestion) => {
                            const style = {
                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                            };
                            return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                    {suggestion.description}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export default AddressInput;

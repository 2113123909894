import { Flex, Input, Text } from '@chakra-ui/react';

type InputProps = {
    label: string;
    value: string | undefined;
    onChange: (newValue: string) => void;
};

const CustomInput: React.FC<InputProps> = ({ label, value, onChange }) => {
    return (
        <Flex direction={"row"} h="60px">
            <Text w={"20%"}>{label}: </Text>
            <Input
                type="text"
                name={label}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                w={"70%"}
            />
        </Flex>
    );
};

export default CustomInput;

import { Button } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function TopBar(){
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    return(
        <>
        <div className="topBar" style={{padding:"10px"}}>
            <Button colorScheme="blue" textColor={"white"} onClick={goBack}>  Back </Button>
        </div>
        </>
    )
}

export default TopBar;